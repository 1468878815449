import React from "react";
import SeO from "../components/seo";
import login from "../assets/img/tutorial/login.png";
import register from "../assets/img/tutorial/register.png";
import email from "../assets/img/tutorial/email.png";

import form_1 from "../assets/img/tutorial/form_1.png";
import form_2 from "../assets/img/tutorial/form_2.png";
import form_3 from "../assets/img/tutorial/form_3.png";
import announcements from "../assets/img/tutorial/announcements.png";
import upload from "../assets/img/tutorial/upload.png";
import final from "../assets/img/tutorial/final.png";



var Carousel = require('react-responsive-carousel').Carousel;

const customFormatter = (current, total) => {
    return current.toString() + ' από ' + total.toString()
}

const ApplicationInformation = () => (
    <React.Fragment>
        <SeO title="Συμπλήρωση Αίτησης" description="Οδηγίες Συμπλήρωσης Αίτησης" />

        <section className="section">
            <div className="container">
                <div className="columns is-centered">
                    <div className="column is-half is-paddingless">
                        <figure>
                            <iframe style={{width:'560px',height:'315px'}} title="Οδηγίες για την υποβολή αίτησης" src="https://www.youtube.com/embed/YW2RviQ8VaM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </figure>
                    </div>
                </div>
            </div>
        </section>
        <hr style={{backgroundColor: '#7b2b2b', marginTop: '5%', marginBottom: '5%'}} />
        <section className="section">
            <div className="container">
                <div className="columns is-centered">
                    <div className="column is-half is-paddingless">
                        <figure>
                            <div style={{maxWidth: '560px'}}>
                                <Carousel showThumbs={false} statusFormatter={ (current, total) => customFormatter(current, total) }>
                                    <div>
                                        <img src={register} alt="" />
                                        <p className="legend" style={{backgroundColor: '#3273dc'}} >Ο χρήστης επιλέγει τον υπερσύνδεσμο «Εγγραφή» και συμπληρώνει τα στοιχεία του.</p>
                                    </div>
                                    <div>
                                        <img src={email} alt="" />
                                        <p className="legend" style={{backgroundColor: '#3273dc'}} >Εφόσον η ταυτοποίηση είναι επιτυχής, ο χρήστης θα λάβει στο λογαριασμό ηλ. ταχυδρομείου που έχει δηλώσει σχετικό μήνυμα που θα περιέχει υπερσύνδεσμο, τον οποίο επιλέγει. Εφόσον ο υπερσύνδεσμος δεν είναι επιλέξιμος, ακολουθεί τη διαδικασία της εικόνας.</p>
                                    </div>
                                    <div>
                                        <img src={login} alt="" />
                                        <p className="legend" style={{backgroundColor: '#3273dc'}} >Ο χρήστης εισάγει τα διαπιστευτήριά του προκειμένου να εισέλθει στην εφαρμογή.</p>
                                    </div>

                                    <div>
                                        <img src={form_1} alt="" />
                                        <p className="legend" style={{backgroundColor: '#3273dc'}} >
                                            Ο χρήστης μπορεί να αποθηκεύσει προσωρινά την αίτηση όσες φορές το επιθυμεί. 
                                            Εφόσον επιλέξει το πλήκτρο «Οριστικοποίηση», δε θα μπορεί να κάνει πλέον αλλαγές,
                                            αλλά μπορεί να ξεκινήσει τη μεταφόρτωση των δικαιολογητικών.
                                            Η αίτηση χωρίζεται σε τρεις υποενότητες: 1. Στοιχεία Υποψηφίου...
                                        </p>
                                    </div>
                                    <div>
                                        <img src={form_2} alt="" />
                                        <p className="legend" style={{backgroundColor: '#3273dc'}} >2. Στοιχεία Αίτησης και...</p>
                                    </div>
                                    <div>
                                        <img src={form_3} alt="" />
                                        <p className="legend" style={{backgroundColor: '#3273dc'}} >3. Βεβαιώσεις</p>
                                    </div>
                                    <div>
                                        <img src={announcements} alt="" />
                                        <p className="legend" style={{backgroundColor: '#3273dc'}} >
                                            Μετά την Οριστικοποίηση της Αίτησης, στο υπομενού «Ανακοινώσεις» ο χρήστης 
                                            θα βρει την Αίτηση - Υπεύθυνη Δήλωση προσυμπληρωμένη, καθώς και το Δελτίο
                                            Υγειονομικής Εξέτασης. Την Αίτηση - Υπεύθυνη Δήλωση τη μεταφορτώνει αφού 
                                            προηγουμένως τη θεωρήσει σε αρμόδια Υπηρεσία (πχ ΚΕΠ), ενώ το Δελτίο 
                                            Υγειονομικής Εξέτασης το προσκομίζει στις εξετάσεις, συμπληρωμένο σύμφωνα
                                            με τις οδηγίες της Εγκυκλίου.
                                        </p>
                                    </div>
                                    <div>
                                        <img src={upload} alt="" />
                                        <p className="legend" style={{backgroundColor: '#3273dc'}} >Ο χρήστης αφού μεταφορτώσει τα απαραίτητα κατά περίπτωση δικαιολογητικά, επιλέγει το πλήκτρο «Αποστολή».</p>
                                    </div>
                                    <div>
                                        <img src={final} alt="" />
                                        <p className="legend" style={{backgroundColor: '#3273dc'}} >Η αίτηση έχει αποσταλεί στο Εξεταστικό Κέντρο της επιλογής του χρήστη.</p>
                                    </div>
                                </Carousel>
                            </div>
                        </figure>
                    </div>
                </div>
            </div>
        </section> 
    </React.Fragment>
)

export default ApplicationInformation;
